<template>
  <b-modal
    id="food-modal-group"
    ref="food-modal-group"
    :title="type == 'create' ? 'Tạo nhóm món ăn' : nameEdit"
    hide-footer
    @hidden="resetModal"
    @ok="handleValidate"
    style="min-width: 800px"
  >
    <b-overlay :show="loading">
      <b-container fluid>
        <form ref="form-group">
          <b-row>
            <b-col>
              <div
                style="
                  width: 150px;
                  height: 150px;
                  background-color: #f3f6f9;
                  box-shadow: 0px 4px 4px 0px #000000 10%;
                  position: relative;
                  margin: auto;
                "
              >
                <v-file-input
                  hide-input
                  v-model="file"
                  accept="image/png, image/jpeg, image/bmp"
                  class="icon-edit"
                  @change="onUpload"
                  v-if="!isDisabled"
                  prepend-icon="mdi-pencil-outline"
                  truncate-length="15"
                ></v-file-input>

                <v-img :src="preview" :aspect-ratio="1 / 1"></v-img>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <basic-input
                label="Mã nhóm món ăn"
                placeholder="Nhập Mã nhóm món ăn"
                :value.sync="form.code"
                name="code"
                :required="true"
                :state="validateState('code')"
                :invalidFeedback="errors.first('code')"
                v-validate="'required|alpha_num'"
                :disabled="isDisabled"
                data-vv-as="Mã nhóm món ăn"
              ></basic-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <basic-input
                label="Tên nhóm món ăn"
                placeholder="Nhập tên nhóm món ăn"
                :value.sync="form.name"
                name="nameGroup"
                :required="true"
                :disabled="isDisabled"
                :state="validateState('nameGroup')"
                :invalidFeedback="errors.first('nameGroup')"
                v-validate="'required'"
                data-vv-as="Tên nhóm món ăn"
              ></basic-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <basic-select
                label="Thuộc nhóm món ăn cấp 1"
                name="groupFoods"
                :disabled="isDisabled"
                placeholder="--- Chọn nhóm món ăn cấp 1 ---"
                :options="category"
                :value.sync="form.parentId"
                data-vv-as="Nhóm món ăn cấp 1"
              />
            </b-col>
          </b-row>
          <div class="d-flex justify-content-end pt-6">
            <b-button
              class="btn btn-success ml-3"
              type="button"
              v-if="!isDisabled"
              @click.stop="handleValidate"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
              </span>
              Lưu
            </b-button>
          </div>
        </form>
      </b-container>
    </b-overlay>
  </b-modal>
</template>

<script>
export default {
  name: 'GroupModal',
  props: {
    type: {
      type: String,
      default: 'create',
    },
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      file: null,
      preview: null,
      parentCategory: [],
      form: {},
      loading: false,
    };
  },
  watch: {
    detail: {
      handler(newVal) {
        if (newVal) {
          let payload = JSON.parse(JSON.stringify(newVal));
          this.form = payload;
          this.preview = payload.image?.url;
          this.form.parentId = this.category.find(
            (e) => e.id === payload.parentId,
          );
        }
      },
    },
  },
  computed: {
    category() {
      return this.parentCategory;
    },
    nameEdit() {
      return `${this.detail?.code || ''} ${this.detail?.name}`;
    },
    isDisabled() {
      return this.type === 'view';
    },
  },
  created() {
    this.loadCategories();
  },
  methods: {
    clear() {
      this.file = null;
      this.preview = null;
      this.form.image = null;
    },
    onUpload() {
      this.preview = URL.createObjectURL(this.file);
    },
    async loadCategories() {
      this.loading = true;
      try {
        let { data } = await this.$api.get('Admin/Food/Category', {
          params: { level: 1 },
        });
        this.parentCategory = data;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi',
          msg: error.message,
        });
      }
      this.loading = false;
    },

    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        } else {
          this.submit();
        }
      });
    },

    async submit() {
      this.loading = true;
      let payload = new FormData();
      payload.append('name', this.form.name);
      payload.append('code', this.form.code);
      payload.append('image', this.file);

      try {
        if (this.type == 'create') {
          this.form.parentId && payload.append('parentId', this.form.parentId);
          await this.$api.post('Admin/Food/Category', payload);
        } else if (this.type == 'copy') {
          if (typeof this.form.parentId === 'object') {
            this.form.parentId &&
              payload.append('parentId', this.form.parentId.id);
          } else {
            this.form.parentId &&
              payload.append('parentId', this.form.parentId);
          }
          await this.$api.post('Admin/Food/Category', payload);
        } else {
          payload.append('id', this.form.id);
          if (typeof this.form.parentId === 'object') {
            this.form.parentId &&
              payload.append('parentId', this.form.parentId.id);
          } else {
            this.form.parentId &&
              payload.append('parentId', this.form.parentId);
          }
          await this.$api.put('Admin/Food/Category', payload);
        }
        this.$toastr.s({
          title: 'Thành công!',
          msg:
            this.type != 'edit'
              ? 'Tạo nhóm món ăn mới thành công!'
              : 'Cập nhật nhóm món ăn mới thành công!',
        });
        this.loading = false;
        this.form.parentId ? this.$emit('load-list') : this.$emit('load-data');
        this.$bvModal.hide('food-modal-group');
      } catch (error) {
        this.loading = false;
        this.$toastr.e({
          title: 'Lỗi',
          msg: error.message,
        });
      }
    },
    resetModal() {
      this.form = {};
      this.file = null;
      this.preview = null;
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.pictureInput {
  position: relative;
}
.icon-edit {
  z-index: 100000;
  position: absolute;
  top: -30px;
  right: -6px;
  padding: 2px 6px;
  box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: #fff;
}
</style>
