<template>
  <b-modal
    id="food-modal"
    ref="food-modal"
    :title="isEditing ? detail.name : 'Tạo món ăn mới'"
    hide-footer
    @hidden="resetModal"
    style="min-width: 800px"
  >
    <b-overlay :show="loading">
      <b-container fluid>
        <food-form
          :detail="detail"
          :type="type"
          :loading.sync="loading"
          @loadData="updateData"
          :categories="categories"
        />
      </b-container>
    </b-overlay>
  </b-modal>
</template>

<script>
import Form from './Form';
export default {
  components: { 'food-form': Form },
  props: {
    type: {
      type: String,
    },
    id: {
      type: String,
      default: null,
    },

    category: { type: Array, default: () => [] },
  },
  watch: {
    category: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.categories = JSON.parse(JSON.stringify(newVal));
      },
    },

    id: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal != null) {
          this.loadData(newVal);
        }
      },
    },
  },
  computed: {
    isEditing() {
      return this.type !== 'create';
    },
    data() {
      return this.detail || {};
    },
  },
  data() {
    return {
      detail: {},
      groupFoods: [],
      categories: [],
      loading: false,
    };
  },
  methods: {
    resetModal() {
      this.detail = {};
      this.$emit('updateId');
    },

    updateData() {
      this.$emit('load-data');
    },

    async loadData(id) {
      this.loading = true;
      try {
        let data = await this.$api.get(`Admin/Food/${id}`);

        this.detail = data.data;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi!',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
#food-modal {
  .modal-dialog {
    max-width: 80%;
  }
}
.aterisk {
  color: red;
}
</style>
