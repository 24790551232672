<template>
  <div class="food-list-page w-100">
    <basic-subheader>
      <template v-slot:actions>
        <b-dropdown
          id="dropdown-right dropdown-form"
          class="dropdown-form-filter mr-2"
          no-caret
          right
        >
          <template #button-content>
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </template>
          <h6 class="d-flex align-items-center mb-0 p-4">
            <span class="svg-icon mr-3">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </h6>
          <b-dropdown-divider> </b-dropdown-divider>
          <b-dropdown-form class="mw-400 form-filter">
            <b-container class="p-0">
              <b-row>
                <b-col>
                  <basic-input
                    label="Mã nhóm món ăn  / mã món ăn"
                    placeholder="Nhập mã nhóm món ăn hoặc món ăn"
                    name="codeParams"
                    :value.sync="filter.code"
                  ></basic-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <basic-input
                    label="Tên món ăn "
                    placeholder="Nhập tên món ăn "
                    name="codeParams"
                    :value.sync="filter.name"
                  ></basic-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <basic-select
                    label="Nhóm món ăn"
                    placeholder="--- Chọn nhóm món ăn ---"
                    name="wards"
                    :options="optionCategory"
                    :value.sync="filter.foodCategoryId"
                    :solid="false"
                    :allowEmpty="false"
                    changeValueByObject
                    trackBy="id"
                    valueLabel="name"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-checkbox-group
                    v-model="filter.isActive"
                    :options="[{ text: 'Lọc Inactive', value: true }]"
                  ></b-form-checkbox-group>
                </b-col>
              </b-row>
            </b-container>
          </b-dropdown-form>
          <b-dropdown-divider> </b-dropdown-divider>
          <div class="d-flex align-items-center justify-content-lg-end m-0 p-4">
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click.stop="resetRequest"
            >
              <span class="svg-icon">
                <inline-svg
                  src="/media/svg/icons/Neolex/Basic/refresh-cw.svg"
                />
              </span>
              Reset bộ lọc
            </b-button>
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click="searchRequest"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
              </span>
              Lọc dữ liệu
            </b-button>
          </div>
        </b-dropdown>
        <template v-if="isWritePermission">
          <b-button
            class="btn btn-success"
            type="button"
            @click.stop="createGroup"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
            </span>
            Thêm nhóm ăn mới
          </b-button>
          <b-button
            class="btn btn-success ml-2"
            type="button"
            @click.stop="createItem"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
            </span>
            Thêm món ăn
          </b-button>
        </template>
      </template>
    </basic-subheader>
    <Wrapper>
      <b-overlay :show="loading">
        <v-data-table
          :headers="headers"
          :items="parentCategory"
          item-key="id"
          :single-expand="false"
          :expanded.sync="expanded"
          :items-per-page="-1"
          :options.sync="options"
          class="fixed-table"
          hide-default-footer
        >
          <template v-slot:header.name="{ header }">
            <v-icon @click.stop="expandAll"
              >mdi-{{
                isExpandedNew
                  ? 'unfold-more-horizontal'
                  : 'unfold-less-horizontal'
              }}
            </v-icon>
            {{ header.text }}
          </template>

          <template v-slot:item="{ item, expand, isExpanded }">
            <tr>
              <td>
                <div>{{ item.code }}</div>
              </td>
              <td>
                <div class="d-flex justify-content-between">
                  <span class="d-flex align-items-center">
                    <div
                      class="svg-icon svg-icon-sm d-flex align-items-center success-color"
                    >
                      <inline-svg
                        @click.stop="expand(!isExpanded)"
                        class="svg-icon mr-2 mw-30 cursor-pointer"
                        :src="`/media/svg/icons/Neolex/Arrows/arrow-${
                          isExpanded ? 'bottom' : 'top'
                        }.svg`"
                      />
                    </div>
                    {{ item.name }}</span
                  >
                  <span>
                    <action-dropdown
                      :value="item"
                      :show_copy="isWritePermission"
                      @view="viewGroup"
                      @edit="editGroup"
                      @copy="copyGroup"
                      :show_delete="false"
                      :show_edit="isWritePermission"
                      :boundary="
                        parentCategory.length > 2 ? 'scrollParent' : 'window'
                      "
                    >
                      <b-dropdown-text
                        v-if="isWritePermission"
                        tag="div"
                        class="navi-item cursor-pointer"
                      >
                        <a
                          class="navi-link cursor-pointer"
                          @click="deleteGroup(item)"
                        >
                          <span
                            class="menu-icon svg-icon svg-icon-sm"
                            :class="
                              item.isActive ? 'text-danger' : 'text-primary'
                            "
                          >
                            <inline-svg
                              class="svg-icon"
                              src="/media/svg/icons/Neolex/Basic/power.svg"
                            />
                          </span>
                          <span
                            class="success navi-text"
                            :class="
                              item.isActive ? 'text-danger' : 'text-primary'
                            "
                          >
                            {{ item.isActive ? 'Inactive' : 'Active' }}
                          </span>
                        </a>
                      </b-dropdown-text>
                    </action-dropdown>
                  </span>
                </div>
              </td>
              <td :colspan="7"></td>
            </tr>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="p-0">
              <v-data-table
                :headers="headers"
                :items="item.subCategories"
                hide-default-footer
                hide-default-header
                :expanded.sync="expandedSub"
                :items-per-page="-1"
                class="fixed-table"
              >
                <template v-slot:item="{ item, expand, isExpanded }">
                  <tr
                    style="border-bottom: 1px solid #ccc; overflow: auto"
                    class="position-static"
                  >
                    <td style="width: 10%">
                      <div class="mw-100">{{ item.code }}</div>
                    </td>
                    <td style="width: 30%">
                      <div
                        class="svg-icon svg-icon-sm d-flex align-items-center justify-content-between success-color ml-6"
                      >
                        <div>
                          <inline-svg
                            @click.stop="expand(!isExpanded)"
                            class="svg-icon mr-2 mw-30 cursor-pointer"
                            :src="`/media/svg/icons/Neolex/Arrows/arrow-${
                              isExpanded ? 'bottom' : 'top'
                            }.svg`"
                          />
                          {{ item.name }}
                        </div>
                        <span>
                          <action-dropdown
                            :value="item"
                            :show_copy="isWritePermission"
                            @view="viewGroup"
                            @edit="editGroup"
                            @copy="copyGroup"
                            :show_delete="false"
                            :show_edit="isWritePermission"
                            :boundary="
                              item.subCategories &&
                              item.subCategories.length > 2
                                ? 'scrollParent'
                                : 'window'
                            "
                          >
                            <b-dropdown-text
                              v-if="isWritePermission"
                              tag="div"
                              class="navi-item cursor-pointer"
                            >
                              <a
                                class="navi-link cursor-pointer"
                                @click="deleteGroup(item)"
                              >
                                <span
                                  class="menu-icon svg-icon svg-icon-sm"
                                  :class="
                                    item.isActive
                                      ? 'text-danger'
                                      : 'text-primary'
                                  "
                                >
                                  <inline-svg
                                    class="svg-icon"
                                    src="/media/svg/icons/Neolex/Basic/power.svg"
                                  />
                                </span>
                                <span
                                  class="success navi-text"
                                  :class="
                                    item.isActive
                                      ? 'text-danger'
                                      : 'text-primary'
                                  "
                                >
                                  {{ item.isActive ? 'Inactive' : 'Active' }}
                                </span>
                              </a>
                            </b-dropdown-text>
                          </action-dropdown>
                        </span>
                      </div>
                    </td>
                    <td style="width: 10%" v-for="item in 6" :key="item"></td>
                  </tr>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="p-0">
                    <v-data-table
                      :headers="headers"
                      :items="item.foods"
                      hide-default-footer
                      hide-default-header
                      :items-per-page="-1"
                      class="fixed-table"
                    >
                      <template
                        v-slot:item="{ item, expand, isExpanded, index }"
                      >
                        <tr class="position-static" :id="`food-row-${index}`">
                          <td style="width: 10%">
                            {{ item.code }}
                          </td>
                          <td style="width: 30%">
                            <div
                              class="d-flex justify-content-between align-items-center"
                            >
                              <div
                                class="d-flex align-items-center justify-content-between ml-10"
                              >
                                <img
                                  :src="item.image.url"
                                  alt=""
                                  onerror="javascript:this.src='/media/placeholder/placeholder-image.png'"
                                  class="img-food"
                                />
                                <span class="ml-3"> {{ item.name }}</span>
                              </div>
                              <span>
                                <action-dropdown
                                  :value="item"
                                  :show_copy="isWritePermission"
                                  @view="viewFood"
                                  @edit="editFood"
                                  @copy="copyFood"
                                  :show_delete="false"
                                  :show_edit="isWritePermission"
                                  :boundary="
                                    item.foods && item.foods.length > 2
                                      ? 'scrollParent'
                                      : 'window'
                                  "
                                >
                                  <b-dropdown-text
                                    v-if="isWritePermission"
                                    tag="div"
                                    class="navi-item cursor-pointer"
                                  >
                                    <a
                                      class="navi-link cursor-pointer"
                                      @click="deleteFood(item)"
                                    >
                                      <span
                                        class="menu-icon svg-icon svg-icon-sm"
                                        :class="
                                          item.isActive
                                            ? 'text-danger'
                                            : 'text-primary'
                                        "
                                      >
                                        <inline-svg
                                          class="svg-icon"
                                          src="/media/svg/icons/Neolex/Basic/power.svg"
                                        />
                                      </span>
                                      <span
                                        class="success navi-text"
                                        :class="
                                          item.isActive
                                            ? 'text-danger'
                                            : 'text-primary'
                                        "
                                      >
                                        {{
                                          item.isActive ? 'Inactive' : 'Active'
                                        }}
                                      </span>
                                    </a>
                                  </b-dropdown-text>
                                </action-dropdown>
                              </span>
                            </div>
                          </td>
                          <td class="text-right">
                            {{ item.portion }} {{ item.unit }}
                          </td>
                          <td class="text-right" style="width: 10%">
                            {{ item.calorie }}
                          </td>
                          <td class="text-right" style="width: 10%">
                            {{ item.protein }}
                          </td>
                          <td class="text-right" style="width: 10%">
                            {{ item.glucose }}
                          </td>
                          <td class="text-right" style="width: 10%">
                            {{ item.lipid }}
                          </td>
                          <td class="text-right" style="width: 10%">
                            {{ item.fibre || 0 }}
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </td>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
        <modal-food
          :isEditing="isEditing"
          :id="idFood"
          :type="typeModalFood"
          :detail="detailFood"
          @load-data="getCategoryParent"
          @updateId="updateId"
          :category="optionCategory"
        />

        <modal-group
          :parentCategory="optionCategory"
          :type="typeModalGroup"
          :detail="detailGroup"
          @load-data="getCategoryParent"
          @load-list="getCategoryParent"
        />
      </b-overlay>
    </Wrapper>
  </div>
</template>

<script>
// const qs = require('query-string');
import Modal from './components/Modal';
import ModalGroup from './components/ModalGroup';
export default {
  name: 'Foods',
  components: { 'modal-food': Modal, 'modal-group': ModalGroup },
  data() {
    return {
      expanded: [],
      expandedSub: [],
      isExpandedNew: true,
      isExpandedOld: true,
      isEditing: false,
      loading: false,
      options: {},
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      filter: {
        foodCategoryId: {},
        default: null,
        code: null,
        status: null,
        sortBy: null,
        sortDesc: null,
        name: null,
      },
      sort: {
        by: null,
        order: null,
      },
      parentCategory: [],
      childCategories: [],
      optionCategory: [],
      headers: [
        {
          text: 'Mã',
          align: 'left',
          value: 'code',
          sortable: true,
          width: '10%',
        },
        {
          text: 'Nhóm/Loại thức ăn',
          value: 'name',
          sortable: true,
          align: 'left',
          width: '30%',
        },
        // { text: 'Nhóm  Loại thức ăn', value: 'name', sortable: true },
        {
          text: 'Khẩu phần',
          value: 'portion',
          sortable: false,
          align: 'center',
          width: '10%',
        },
        {
          text: 'Calo (cals)',
          value: 'calorie',
          sortable: true,
          align: 'center',
          width: '10%',
        },
        {
          text: 'Đạm (g)',
          value: 'protein',
          sortable: true,
          align: 'center',
          width: '10%',
        },
        {
          text: 'Đường (g)',
          value: 'glucose',
          sortable: true,
          align: 'center',
          width: '10%',
        },
        {
          text: 'Béo (g)',
          value: 'lipid',
          sortable: true,
          align: 'center',
          width: '10%',
        },
        {
          text: 'Xơ (g)',
          value: 'fibre',
          sortable: true,
          align: 'center',
          width: '10%',
        },
      ],
      foods: [],
      idFood: null,
      typeModalFood: null,
      typeModalGroup: null,
      detailFood: null,
      detailGroup: null,
      queryString: null,
      fixedCategory: null,
    };
  },
  computed: {
    searchParams() {
      return {
        // foodCategoryId: this.filter.foodCategoryId?.id,
        page: 1,
        size: 999,
        name: null,
        code: this.filter.code,
        excludeInactive: this.filter.isActive?.[0] == true ? true : false,
        orderBy: this.filter.sortBy
          ? `${this.filter.sortBy} ${this.filter.sortDesc}`
          : null,
        categoryOrderBy:
          this.filter.sortBy === 'name' || this.filter.sortBy === 'code'
            ? `${this.filter.sortBy} ${this.filter.sortDesc}`
            : null,
      };
    },
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  watch: {
    options: {
      handler(newVal) {
        //eslint-disable-next-line
        let { sortBy, sortDesc } = newVal;
        let [order] = sortDesc;
        let [sort] = sortBy;
        this.filter.sortBy = sort;

        this.filter.sortDesc = order ? 'desc' : 'asc';
        this.loadData();
      },
      deep: true,
    },
  },
  methods: {
    pagingAction() {
      // this.loadData();
    },
    createItem() {
      this.isEditing = true;
      this.detailFood = null;
      this.typeModalFood = 'create';
      this.$bvModal.show('food-modal');
    },
    copyGroup(item) {
      this.typeModalGroup = 'copy';
      this.detailGroup = { ...item };
      this.$bvModal.show('food-modal-group');
    },

    createGroup() {
      this.typeModalGroup = 'create';
      this.$bvModal.show('food-modal-group');
    },
    viewGroup(item) {
      this.detailGroup = { ...item };
      this.typeModalGroup = 'view';
      this.$bvModal.show('food-modal-group');
    },
    editGroup(item) {
      this.detailGroup = { ...item };
      this.typeModalGroup = 'edit';
      this.$bvModal.show('food-modal-group');
    },
    async deleteGroup(item) {
      let state = item.isActive == true ? 'deactivate' : 'activate';
      try {
        this.loading = true;
        await this.$api.post(`Admin/Food/Category/${item.id}/${state}`);
        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Cập nhật thành công',
        });
        this.getCategoryParent();
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },
    updateId() {
      this.idFood = null;
    },
    copyFood(item) {
      this.idFood = item.id;
      this.createItem();
    },
    editFood(item) {
      this.idFood = item.id;
      this.detailFood = item;
      this.typeModalFood = 'edit';
      this.$bvModal.show('food-modal');
    },

    viewFood(item) {
      this.idFood = item.id;
      this.detailFood = item;
      this.typeModalFood = 'view';
      this.$bvModal.show('food-modal');
    },
    async deleteFood(item) {
      this.loading = true;
      let payload = new FormData();

      payload.append('isActive', !item.isActive);
      payload.append('id', item.id);

      try {
        await this.$api.put('Admin/Food', payload);
        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Cập nhật thành công',
        });
        this.getCategoryParent();
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },

    searchRequest() {
      this.getCategoryParent();
      document.body.click();
    },
    resetRequest() {
      this.filter.foodCategoryId = { ...this.fixedCategory };
      this.filter.default = null;
      this.filter.code = null;
      this.filter.status = null;
      this.filter.sortBy = null;
      this.filter.sortDesc = null;
      this.filter.name = null;
      this.filter.isActive = [];
      this.loadData();
      document.body.click();
    },
    sortRequest() {
      return;
    },
    async getCategoryParent() {
      this.loading = true;
      let id = this.filter.foodCategoryId.id;
      try {
        let { data } = await this.$api.get(`Admin/Diet/FoodCategory/${id}`, {
          params: { ...this.searchParams },
        });

        this.parentCategory =
          data && Object.keys(data).length > 0
            ? [
                {
                  ...data,
                  subCategories: data.subCategories.map((e) => ({
                    ...e,
                    parentId: data.id,
                  })),
                },
              ]
            : [];
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },
    async loadCategoriesForFilter() {
      try {
        let { data } = await this.$api.get('/Admin/Diet/FoodCategory');
        this.optionCategory = data;
        this.filter.foodCategoryId = data[0];
        this.fixedCategory = data[0];
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      }
    },
    async loadData() {
      await this.loadCategoriesForFilter();
      await this.getCategoryParent();
    },
  },
  created() {
    this.loadData();
  },
};
</script>
<style lang="scss" scoped>
.form-filter {
  min-width: 450px;
}
.cursor-pointer {
  cursor: pointer;
}
.mw-250 {
  max-width: 250px !important;
  width: 250px !important;
}
.mw-100 {
  max-width: 100px !important;
  width: 100px !important;
}
.mw-200 {
  max-width: 200px !important;
  width: 200px !important;
}
.mw-30 {
  max-width: 30px !important;
  width: 30px !important;
}

.handle {
  cursor: move !important;
  cursor: -webkit-grabbing !important;
}
.img-food {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: #f3f6f9;
}
</style>
<style lang="scss">
.fixed-table {
  table {
    table-layout: fixed;
  }
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  white-space: nowrap;
}
</style>
